
import {defineComponent, ref} from "vue";
import WidgetModal from "@/components/modals/WidgetModal.vue";
import {WidgetViewModel} from "@/models/WidgetViewModel";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import {Organization} from "@/models/organizations/Organization";
import {bus} from "@/modules/eventBus";
import {email, required, url} from "@vuelidate/validators";
import {OrganizationService} from "@/services/OrganizationService";
import {ProblemDetails} from "@/models/ProblemDetails";
import {UpsertOrganizationCommand} from "@/models/organizations/UpsertOrganizationCommand";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import { WebAnalytics } from "@/modules/webAnalytics";

export default defineComponent({
  setup() {
    const widgetModal = ref<InstanceType<typeof WidgetModal>>();
    const invokeAddWidget = () => {
      widgetModal.value?.invokeAdd()
    }
    const invokeUpdateWidget = (widget: WidgetViewModel) => {
      widgetModal.value?.invokeUpdate(widget)
    }

    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const userStore = useUser()
    const applicationStore = useApplication()
    const toast = useToast();
    return {
      widgetModal,
      invokeAddWidget, invokeUpdateWidget,
      v$: useVuelidate(),
      handleAvatarSuccess,
      beforeAvatarUpload,
      userStore,
      applicationStore,
      toast
    }
  },
  components: {Button, Selector2},
  data() {
    return {
      organization: {} as Organization,
      isLoading: false,
      isFetching: false,
      isEntityLoading: false,
      photoIsLoading: false,
      hasAvatarQueued: false,
      organizationTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Professional Theatre',
          id: 1
        }, {
          text: 'Community Theatre',
          id: 2
        }, {
          text: 'Education',
          id: 3
        }, {
          text: 'Dance',
          id: 4
        }, {
          text: 'Choir',
          id: 5
        }, {
          text: 'Tour',
          id: 6
        }, {
          text: 'Individual',
          id: 7
        },
      ],
      orgPhone: '',
    };
  },
  mounted() {
    this.fetch()
    bus.on('modals:cropper:handle:entity-settings', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  unmounted() {
    bus.off('modals:cropper:handle:entity-settings', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  validations() {
    return {
      organization: {
        email: {required, email},
        name: {required},
        phone: {required},
        website: {required, url}
      }
    }
  },
  methods: {
    fetch() {
      this.isFetching = true
      if (this.isOrganization) {
        OrganizationService.fetch(this.organizationId).then(response => {
          this.organization = response.data
          this.orgPhone = response.data.phone
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isFetching = false
        })
      }
    },
    update() {
      if (this.isOrganization) {
        this.v$.organization.$touch()
        if (!this.v$.organization.$invalid) {
          this.isLoading = true
          OrganizationService.update(this.organizationId, {
            description: this.organization.description,
            email: this.organization.email,
            name: this.organization.name,
            phone: this.organization.phone,
            type: this.organization.type,
            website: this.organization.website
          } as UpsertOrganizationCommand).then(() => {
            this.toast.success('Organization updated')
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.isLoading = false
            WebAnalytics.trackFlexible('Updated Organization', {
              organizationId: this.organizationId,
            })
          })
        }
      }
    },
    requestCropper(xhr) {
      bus.emit('modals:cropper:open', {
        blob: xhr.file,
        requester: 'modals:cropper:handle:entity-settings',
        isUserPhoto: false
      })
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      if (this.isOrganization) {
        OrganizationService.uploadPhoto(this.organizationId, formData).then(response => {
          this.organization.photo = response.data.avatarUrl
          this.toast.success('Photo Uploaded')
          this.hasAvatarQueued = false
          this.userStore.fetchOrganizationMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
          WebAnalytics.trackFlexible('Uploaded Organization Photo', {
            organizationId: this.organizationId,
          })
        })
      }
    },
    removePhoto() {
      this.photoIsLoading = true
      if (this.isOrganization) {
        OrganizationService.removePhoto(this.organizationId).then(() => {
          this.organization.photo = ""
          this.toast.success("Photo Removed")
          this.userStore.fetchOrganizationMemberships()
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.photoIsLoading = false
          WebAnalytics.trackFlexible('Removed Organization Photo', {
            organizationId: this.organizationId,
          })
        })
      }
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    hasAvatar(): boolean {
      return this.organization.photo !== '' && this.organization.photo !== null
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
});
