
import {defineComponent} from "vue";
import OrganizationGeneralSettings from "@/components/Entity/Settings/OrganizationGeneralSettings.vue"
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import ProductionGeneralSettings from "@/components/Entity/Settings/ProductionGeneralSettings.vue";

export default defineComponent({
  setup() {
    const userStore = useUser()
    const applicationStore = useApplication()
    return {
      userStore,
      applicationStore
    }
  },
  components: {ProductionGeneralSettings, OrganizationGeneralSettings},
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    }
  },
})
