import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-xl-8" }
const _hoisted_3 = { class: "card mt-2" }
const _hoisted_4 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrganizationGeneralSettings = _resolveComponent("OrganizationGeneralSettings")!
  const _component_ProductionGeneralSettings = _resolveComponent("ProductionGeneralSettings")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isOrganization)
            ? (_openBlock(), _createBlock(_component_OrganizationGeneralSettings, { key: 0 }))
            : (_openBlock(), _createBlock(_component_ProductionGeneralSettings, { key: 1 }))
        ])
      ])
    ])
  ]))
}