
import {defineComponent, ref} from "vue";
import {Production} from "@/models/productions/Production";
import {WidgetViewModel} from "@/models/WidgetViewModel";
import {bus} from "@/modules/eventBus";
import {required,} from "@vuelidate/validators";
import {ProblemDetails} from "@/models/ProblemDetails";
import {ProductionService} from "@/services/ProductionService";
import WidgetModal from "@/components/modals/WidgetModal.vue";
import {UploadFile, UploadProgressEvent, UploadRawFile} from "element-plus/es/components/upload/src/upload";
import {ElMessage} from "element-plus";
import {useUser} from "@/store/pinia/userStore";
import {useApplication} from "@/store/pinia/applicationStore";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import { WebAnalytics } from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button, Selector2},
  setup() {
    const widgetModal = ref<InstanceType<typeof WidgetModal>>();
    const invokeAddWidget = () => {
      widgetModal.value?.invokeAdd()
    }
    const invokeUpdateWidget = (widget: WidgetViewModel) => {
      widgetModal.value?.invokeUpdate(widget)
    }

    const imageUrl = ref('')
    const handleAvatarSuccess = (res: UploadProgressEvent, file: UploadFile) => {
      imageUrl.value = URL.createObjectURL(file.raw)
    }
    const beforeAvatarUpload = (file: UploadRawFile) => {
      const isJPG = file.type.indexOf('image') > -1
      const isLt2M = file.size / 1024 / 1024 < 8

      if (!isJPG) {
        ElMessage.error('Avatar picture must be an image')
      }
      if (!isLt2M) {
        ElMessage.error('Avatar picture size can not exceed 8MB')
      }
      return isJPG && isLt2M
    }
    const userStore = useUser()
    const applicationStore = useApplication()
    const toast = useToast();
    return {
      widgetModal,
      invokeAddWidget, invokeUpdateWidget,
      v$: useVuelidate(),
      handleAvatarSuccess,
      beforeAvatarUpload,
      userStore,
      applicationStore,
      toast
    }
  },
  data() {
    return {
      production: {} as Production,
      isLoading: false,
      isFetching: false,
      photoIsLoading: false,
      hasAvatarQueued: false,
      productionTypeOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Musical',
          id: 1
        }, {
          text: 'Play',
          id: 2
        }, {
          text: 'Dance',
          id: 4
        }, {
          text: 'Opera',
          id: 3
        }, {
          text: 'Concert',
          id: 5
        }
      ],
      widget: {} as WidgetViewModel,
      widgets: [] as Array<WidgetViewModel>
    };
  },
  mounted() {
    this.fetch()
    bus.on('modals:cropper:handle:entity-settings', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  unmounted() {
    bus.off('modals:cropper:handle:entity-settings', (blob) => {
      this.uploadPhoto(blob)
    })
  },
  validations() {
    return {
      production: {
        name: {required}
      }
    }
  },
  methods: {
    fetch() {
      this.isFetching = true
      ProductionService.fetch(this.productionId).then(response => {
        this.production = response.data
        if (this.production.metadata) {
          const widgetMetadataRaw = response.data.metadata.filter(x => x.type === 1);
          if (widgetMetadataRaw.length > 0) {
            this.widgets = JSON.parse(widgetMetadataRaw[0].value);
          }
        }
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.isFetching = false
      })
    },
    update() {
      this.v$.production.$touch()
      if (!this.v$.production.$invalid) {
        this.isLoading = true
        ProductionService.update(this.productionId,
            {
              description: this.production.description,
              name: this.production.name,
              type: this.production.type,
              productionId: this.productionId,
              metadata: this.production.metadata
            }).then(() => {
          this.toast.success('Production updated')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Updated Production', {
            productionId: this.productionId,
          })
        })
      }
    },
    requestCropper(xhr){
      bus.emit('modals:cropper:open', {blob: xhr.file, requester: 'modals:cropper:handle:entity-settings', isUserPhoto: false})
    },
    uploadPhoto(blob) {
      this.photoIsLoading = true
      const formData = new FormData();
      formData.append('file', blob);
      ProductionService.uploadPhoto(this.productionId, formData).then(response => {
        this.production.photo = response.data.avatarUrl
        this.hasAvatarQueued = false
        this.toast.success('Photo Uploaded')
        this.userStore.fetchProductionMemberships()
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.photoIsLoading = false
        WebAnalytics.trackFlexible('Uploaded Production Photo', {
          productionId: this.productionId,
        })
      })
    },
    removePhoto() {
      this.photoIsLoading = true
      ProductionService.removePhoto(this.productionId).then(() => {
        this.production.photo = ""
        this.toast.success("Photo Removed")
        this.userStore.fetchProductionMemberships()
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      }).finally(() => {
        this.photoIsLoading = false
        WebAnalytics.trackFlexible('Removed Production Photo', {
          productionId: this.productionId,
        })
      })
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    hasAvatar(): boolean {
      return this.production.photo !== '' && this.production.photo !== null
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
});
